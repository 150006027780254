import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

import { useContenfulSocialNetworks } from "../contenful/querys/SocialNetwork"
import { socialNetworks as networksAvailable } from '../@common/constansts/socialNetworks'
import { SITE_URL } from '../@common/environments/environment'

function SEO({
  lang,
  title,
  description,
  fb_appId,
  keywords,
  og_description,
  og_imagen,
  og_sitio,
  og_title,
  og_type,
  og_url,
  twitter_card,
  twitter_image_alt,
  twitter_imagen,
  twitter_site,
  twitter_description,
}) {

  const socialNetworks = []
  const socialNetworksContentful = useContenfulSocialNetworks()

  socialNetworksContentful.forEach(item => {
    networksAvailable.map(red => {
      if (!socialNetworks.includes(item[`${red}_url`]))
        socialNetworks.push(item[`${red}_url`])
    })
  })

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={title}
      meta={[
        { name: `description`, content: description },
        { name: `keywords`, content: keywords },
        { property: `og:type`, content: og_type },
        { property: `og:title`, content: og_title },
        { property: `og:description`, content: og_description },
        { property: `og:image`, content: og_imagen },
        { property: `og:url`, content: og_url },
        { property: `og:site_name`, content: og_sitio },
        { property: `fb:app_id`, content: fb_appId },
        { name: `twitter:card`, content: twitter_card },
        { name: `twitter:image:alt`, content: twitter_image_alt },
        { name: `twitter:site`, content: twitter_site },
        { name: `twitter:image`, content: twitter_imagen },
        { name: `twitter:title`, content: twitter_card },
        { name: `twitter:description`, content: twitter_description },
      ]}
    >
      <script type="application/ld+json">{`
        {
            "@context": "http://schema.org",
            "@type": "Organization",
            "url": "${SITE_URL}",
            "name": "Liftit",
            "sameAs": ["${socialNetworks.join('","')}"]
        }
      `}</script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
}

SEO.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  fb_appId: PropTypes.string,
  keywords: PropTypes.string,
  og_description: PropTypes.string,
  og_imagen: PropTypes.string,
  og_sitio: PropTypes.string,
  og_title: PropTypes.string,
  og_type: PropTypes.string,
  og_url: PropTypes.string,
  twitter_card: PropTypes.string,
  twitter_image_alt: PropTypes.string,
  twitter_imagen: PropTypes.string,
  twitter_site: PropTypes.string,
  twitter_description: PropTypes.string,
}

export default SEO
